<template>
  <div class="new">
    <div class="new-banner">
      <img :src="require('@imgs/new/banner.png')" v-if="$isMobile()" />
      <img :src="require('@imgs/new/web/banner.png')" v-else>
      <div class="new-banner-title">
        <h3>新闻中心</h3>
        <!-- <span>NEWS AND INFORMATION</span> -->
      </div>
    </div>
    <div class="new-breadcrumb">
      当前位置:<span @click="$router.push({ path: '/' })">首页</span> -> <span>新闻资讯</span>
    </div>
    <new-content @change="change" class="new-content"> </new-content>
  </div>
</template>
<script>
import newContent from './new-content'
export default {
  name: 'new',
  components: {
    newContent
  },
  data() {
    return {
      newTabItem: '行业资讯'
    }
  },
  methods: {
    tabChange(item) {
      this.isActive = item
    },
    change(item) {
      this.newTabItem = item
    }
  }
}
</script>
<style scoped lang="scss">
.new {
  position: relative;

  &-banner {
    width: 100%;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;

      h3 {
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        line-height: .8;
        font-family: Microsoft YaHei;
      }

      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        font-family: Microsoft YaHei;
      }
    }
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px;
    background: #fff;
    margin-bottom: 40px;
  }

  &-header {
    margin-top: 60px;
    align-items: center;
  }

  &-breadcrumb {
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    font-family: Microsoft YaHei;

    span {
      cursor: pointer;
    }
  }

  &-tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &-tabs-item {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }

  &-tabs-item--active {
    background: #0862ca;
    color: #fff;
  }
}</style>